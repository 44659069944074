Object.defineProperty(exports, '__esModule', { value: true });
const prefix = 'fab';
const iconName = 'loginshield';
const width = 1240;
const height = 1240;
const ligatures = [];
const unicode = 'f179'; // just a placeholder, unknown how font awesome team assigns these
const svgPathData = 'M628.8,1165l-5.6-3.2c-263-153.3-411.5-385.3-441.1-689.6c-16.6-169.8,9.8-319.6,27-393.8l2-8.6h417.7V3.3H160.3c0,0-276,840.4,468.5,1237.8V1165zM628.8,3.3v66.5h417.7l2,8.6c17.2,74.2,43.5,224.1,27,393.8c-29.6,304.3-178.1,536.3-441.1,689.6l-5.6,3.2l0,0v76l0,0C1373.3,843.7,1097.3,3.3,1097.3,3.3H628.8zM438.1,798.1c-15.6,0-23.4-7.8-23.4-23.4V276.5c0-15.6,7.8-23.4,23.4-23.4h124.6c15.6,0,23.4,7.8,23.4,23.4v373.7h42.8V69.8H211.1l-2,8.6c-17.2,74.2-43.5,224.1-27,393.8c29.6,304.3,178.1,536.3,441.1,689.6l5.6,3.2l0,0v-367H438.1zM1048.5,78.4l-2-8.6H628.8v580.4h0h229.6h0c15.6,0,23.3,7.8,23.3,23.4v101.2c0,7.8-1.9,13.6-5.8,17.5c-3.9,3.9-9.7,5.8-17.5,5.8H628.8v367l0,0l5.6-3.2c263-153.3,411.5-385.3,441.1-689.6C1092.1,302.5,1065.7,152.6,1048.5,78.4z';

exports.definition = {
    prefix,
    iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData,
    ],
};

exports.faLoginshield = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
