import Vue from 'vue';
import Vuex from 'vuex';

import client from '../sdk/client-factory';

Vue.use(Vuex);

/*
Do not redirect user from here (no $router.push). Respond to calling code
with appropriate indicators and let the calling code redirect as needed.
*/

export default new Vuex.Store({
    state: {
        focus: null,
        accountId: null, // account id, set by App.vue for /account routes
        account: null, // current account; populated when the path has the `accountId` parameter; see App.vue
        isReady: false, // indicates that we loaded session info from server, so we know if user is authenticated; and if user is authenticated, that we've also loaded user info and account info from server
        serviceInfo: {}, // registration_mode, stripeTokenPublicKey
        // serviceVersion: {}, // version
        session: { isAuthenticated: false }, // isAuthenticated, notAfter, isCsrfGuardEnabled
        user: {}, // name, email, sessionIdleExpiresMillis
        interactionMap: {}, // id => interaction ( type: string, next: string, state: object )
        nav: { queue: [] }, // queue  with items pushed to it, so whenever we are done with something and want to know where to return to, we pop the last item from the queue and go there; and each item can have a function to determine if it's still valid (and the user should be directed there) or if it should be ignored (remove from the queue and proceed to next item)
        loadingMap: { init: true },
        brandprofile: null,
        // TODO: eventually load the palette from cdn.brandprofile.org; but only when we implement custom hostnames for enterprise-branded loginfront instance; so for now we just have our own palette here hard-coded
        // palette: null,
        palette: {
            content: {
                text: '#000000', // black
                primary: [{ hex: '#3F51B5' }], // loginfront indigo
                primaryText: '#ffffff', // white; text color that should be used when text is over the primary color
                secondary: '#8C9EFF', // indigo accent-1
                accent: [{ hex: '#7986CB' }], // indigo lighten-3
                background: '#FAFAFA', // grey lighten-5
            },
        },
        /*
        We use a default palette with greys to minimize the flicker that
        happens when the actual brand palette is finally loaded; if we
        use LibertyDNS brand colors, the flicker is very noticeable on
        a custom-branded site
        */
        defaultPalette: {
            text: '#000000', // black
            primary: '#BDBDBD', // grey lighten-1
            primaryText: '#ffffff', // white; text color that should be used when text is over the primary color
            secondary: '#E0E0E0', // grey lighten-2
            accent: '#B0BEC5', // blue-grey lighten-3
            background: '#F5F5F5', // grey lighten-4
        },
        brandNotFoundError: false,
    },
    getters: {
        isLoading(state) {
            return Object.values(state.loadingMap).reduce((acc, item) => acc || item, false);
        },
        primaryColor(state) {
            let result;
            if (Array.isArray(state.palette?.content?.primary) && state.palette.content.primary.length > 0) {
                result = state.palette.content.primary[0].hex;
            } else {
                result = state.defaultPalette.primary;
            }
            // TODO: input validation that palette primary color is a valid hex color value or html color name
            return result;
        },
        primaryTextColor(state) {
            return state.defaultPalette.primaryText;
        },
        accentColor(state) {
            let result;
            if (Array.isArray(state.palette?.content?.accent) && state.palette.content.accent.length > 0) {
                result = state.palette.content.accent[0].hex;
            } else {
                result = state.defaultPalette.accent;
            }
            // TODO: input validation that palette accent color is a valid hex color value or html color name
            return result;
        },
        cardTitleBarTextStyle(state, getters) {
            return `color: ${getters.primaryTextColor}`;
        },
        cardTitleBarStyle(state, getters) {
            return `background-color: ${getters.primaryColor}`;
        },
        primaryButtonStyle(state, getters) {
            return `color: ${getters.primaryTextColor}; background-color: ${getters.primaryColor};`;
        },
        primaryIconButtonStyle(state, getters) {
            return `color: ${getters.primaryColor}; caret-color: ${getters.primaryColor};`;
        },
        primaryIconStyle(state, getters) {
            return `color: ${getters.primaryColor};`;
        },
        primaryTextStyle(state, getters) {
            return `color: ${getters.primaryColor};`;
        },
        isPermitSystemAdmin(state) {
            return Array.isArray(state.user?.permit?.role) && state.user.permit.role.includes('service-admin');
        },
    },
    mutations: {
        focus(state, timestamp) {
            state.focus = timestamp;
        },
        ready(state) {
            console.log('vuex store: ready');
            state.isReady = true;
        },
        setServiceInfo(state, serviceInfo) {
            state.serviceInfo = serviceInfo;
        },
        setSession(state, session) {
            state.session = session;
        },
        user(state, user) {
            state.user = user;
        },
        setAccount(state, account) {
            state.account = account;
        },
        setInteraction(state, interaction) {
            state.interactionMap = { ...state.interactionMap, ...interaction };
        },
        setNav(state, nav) {
            state.nav = nav;
        },
        loading(state, progress) {
            state.loadingMap = { ...state.loadingMap, ...progress };
        },
    },
    actions: {
        async createAccount({ commit, dispatch, state }, accountInfo) {
            commit('loading', { createAccount: true });
            const response = await client.user(state.user.id).account.create(accountInfo);
            if (response.isCreated) {
                await dispatch('loadSession');
                if (state.session.isAuthenticated && state.session.userId) {
                    await dispatch('loadUser');
                    // await dispatch('loadAccount');
                }
            }
            commit('loading', { createAccount: false });
            return response;
        },
        async logout({ commit }) {
            commit('loading', { logout: true });
            await client.main().authn.logout();
            // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
            commit('setSession', { isAuthenticated: false });
            commit('loading', { logout: false });
        },
        // async enableCsrfGuard({ commit, state }) {
        //     const csrfTokenResponse = await client.authn.createCsrfToken();
        //     if (csrfTokenResponse.token) {
        //         const csrfGuardToken = csrfTokenResponse.token;
        //         localStorage.setItem('csrfGuardToken', csrfGuardToken);
        //         commit('setSession', { ...state.session, isCsrfGuardEnabled: true, csrfGuardToken });
        //     }
        // },
        async init({ commit, dispatch, state }, { force = false } = {}) {
            if (state.isReady && !force) {
                console.log('vuex store: init already done');
                return;
            }
            console.log('vuex store: init');
            commit('loading', { init: true });
            try {
                await Promise.all([
                    // dispatch('loadServiceInfo'), // TODO: the only thing here was registraiton mode, which is now moving to unicorn springs... if we need to show anything like temporary maintenance window, we could return that info in the session API , since we reload the session periodically that's a better place anyway
                    dispatch('loadSession'),
                ]);
                console.log('vuex store: loaded service info and session');
                /*
                if (state.session.isCsrfGuardEnabled && state.session.csrfGuardToken) {
                    // csrf guard enabled, store the token for use by loginshiedl client
                    localStorage.setItem('csrfGuardToken', state.session.csrfGuardToken);
                } else {
                    // enable csrf guard
                    await dispatch('enableCsrfGuard');
                }
                */
                if (state.session.isAuthenticated) {
                    // load data concurrently
                    await Promise.all([
                        dispatch('loadUser'),
                    ]);
                }
                console.log('vuex store: ready');
                commit('ready');
            } catch (err) {
                console.error('vuex store: init failed');
            }
            commit('loading', { init: false });
        },
        /**
         * Reload session. If authenticated, also reload user info.
         * @param {*} param0 vuex parameters
         * @param {*} param1 optional object { progressIndicator }, default false because we call refresh automatically when window gains focus, but in some cases the UI calls refresh and wants to show a progress bar so it can be set to true
         */
        async refresh({ dispatch, state }, { progressIndicator = false } = {}) {
            console.log('vuex store: refresh');
            // not displaying loading bar because we want this to be transparent
            try {
                await dispatch('loadSession', { progressIndicator });
                if (state.session.isAuthenticated) {
                    await dispatch('loadUser', { progressIndicator });
                }
            } catch (err) {
                console.error('vuex store: refresh failed');
            }
        },
        /*
        async loadServiceInfo({ commit }) {
            commit('loading', { loadServiceInfo: true });
            try {
                const [serviceInfo] = await Promise.all([
                    client.main().system.getInfo(),
                ]);
                // commit('setServiceVersion', versionInfo);
                commit('setServiceInfo', serviceInfo);
            } catch (err) {
                console.error('vuex store: failed to load service info');
            }
            commit('loading', { loadServiceInfo: false });
        },
        */
        async loadSession({ commit, dispatch, state }, { progressIndicator = true } = {}) {
            if (progressIndicator) {
                commit('loading', { loadSession: true });
            }
            try {
                const sessionInfo = await client.main().authn.get();
                console.log(`vuex store: session ${JSON.stringify(sessionInfo)}`);
                const now = Date.now();
                const {
                    authenticated = false,
                    authenticated_duration: authenticatedDuration = null,
                    unlocked = false,
                    unlocked_duration: unlockedDuration = null,
                    // duration = null,
                    refresh_after_duration: refreshAfterDuration = null,
                    etag = {},
                    user_id: userId = null,
                } = sessionInfo;
                let { reloadTimeoutId } = state.session;
                if (authenticated && typeof refreshAfterDuration === 'number' && refreshAfterDuration > 0) {
                    // clear a previous timeout, if it exists
                    if (reloadTimeoutId) {
                        console.log(`vuex store: clearing timeout ${reloadTimeoutId}`);
                        clearTimeout(reloadTimeoutId);
                    }
                    console.log(`vuex store: scheduling session reload for ${refreshAfterDuration} ms`);
                    reloadTimeoutId = setTimeout(() => {
                        console.log('vuex store: reloading session');
                        dispatch('loadSession');
                    }, refreshAfterDuration);
                }
                commit('setSession', {
                    isAuthenticated: authenticated,
                    isUnlocked: unlocked,
                    authenticatedNotAfter: typeof authenticatedDuration === 'number' && authenticatedDuration > 0 ? now + authenticatedDuration : null,
                    unlockedNotAfter: typeof unlockedDuration === 'number' && unlockedDuration > 0 ? now + unlockedDuration : null,
                    nextRefresh: typeof refreshAfterDuration === 'number' && refreshAfterDuration > 0 ? now + refreshAfterDuration : null,
                    etag,
                    reloadTimeoutId,
                    userId,
                });
            } catch (err) {
                commit('setSession', { fault: { type: 'read-failed' } });
            }
            commit('loading', { loadSession: false });
        },
        async loadUser({ commit, state }, { progressIndicator = true } = {}) {
            if (progressIndicator) {
                commit('loading', { loadUser: true });
            }
            try {
                // NOTE: this is the only place where we use state.session.user_id to access a user API; after this, state.user will be set and all other calls to user API should use state.user.id
                const userInfo = await client.user(state.session.userId).self.get();
                commit('user', userInfo);
            } catch (err) {
                commit('user', { fault: { type: 'read-failed' } });
            } finally {
                commit('loading', { loadUser: false });
            }
        },
        async loadAccount({ commit }, { accountId, progressIndicator = true } = {}) {
            if (progressIndicator) {
                commit('loading', { loadAccount: true });
            }
            try {
                const accountInfo = await client.account(accountId).self.get();
                commit('setAccount', accountInfo);
            } catch (err) {
                console.log('loadAccount failed', err);
                commit('setAccount', null);
            }
            commit('loading', { loadAccount: false });
        },
        // async editSession({ commit }, sessionInfo) {
        //     commit('loading', { editSession: true });
        //     let isEdited = false;
        //     try {
        //         const newSessionInfo = await client.authn.edit(sessionInfo);
        //         commit('setSession', newSessionInfo);
        //         isEdited = true;
        //     } catch (err) {
        //         console.log('editSession error: %o', err);
        //     }
        //     commit('loading', { editSession: false });
        //     return isEdited;
        // },
        async editCurrentUser({ commit, state }, userInfo) {
            try {
                commit('loading', { editCurrentUser: true });
                const { isEdited } = await client.user(state.user.id).self.edit(userInfo);
                // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
                const newUserInfo = { ...state.user, info: { ...state.user.info, ...userInfo } };
                commit('user', newUserInfo);
                return isEdited;
            } catch (err) {
                console.error('editCurrentUser error', err);
                return false;
            } finally {
                commit('loading', { editCurrentUser: false });
            }
        },
        async createInteraction({ commit }, interaction) {
            commit('loading', { createInteraction: true });
            console.log('store: createInteraction %o', interaction);
            const response = await client.main().interaction.create(interaction);
            if (response.id) {
                commit('setInteraction', { [response.id]: response });
            }
            commit('loading', { createInteraction: false });
            return response;
        },
        async editInteraction({ commit }, { interactionId, message }) {
            commit('loading', { editInteraction: true });
            console.log('store: editInteraction %s', interactionId);
            const response = await client.main().interaction.edit(interactionId, message);
            if (response.id) {
                commit('setInteraction', { [response.id]: response });
            }
            commit('loading', { editInteraction: false });
            return response;
        },
        async loadInteraction({ commit }, interactionId) {
            commit('loading', { loadInteraction: true });
            const response = await client.main().interaction.get(interactionId);
            commit('setInteraction', { [interactionId]: response });
            commit('loading', { loadInteraction: false });
            return response;
        },
        queueNavItem({ commit, state }, item) {
            const queue = [...state.nav.queue];
            queue.push({ path: item.path, query: item.query || {}, hash: item.hash || '' });
            commit('setNav', { queue });
        },
        nextNavItem({ commit, state }) {
            try {
                const queue = [...state.nav.queue];
                if (queue.length > 0) {
                    const next = queue.splice(queue.length - 1, 1);
                    commit('setNav', { queue });
                    return next[0];
                }
                if (state.session.isAuthenticated) {
                    return { path: '/dashboard' };
                }
                return { path: '/' };
            } catch (err) {
                console.log('forward error: %o', err);
                return { path: '/' };
            }
        },
    },
});
