/*!
Copyright (C) 2020 Cryptium Corporation. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postUploadAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

class Account {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    // to list accounts, use this.$client.user(userId).account.search() for user's accounts or this.$client.main().account.search() for all accounts (sysadmin only)
    // async list(request) {
    //     return getJson(`${this.url}/search/sef/list`, request, { requestHeaders: this.requestHeaders });
    // }

    // to create a new account, use this.$client.user(userId).account.create() to create account with current user as admin or this.$client.main().account.create() to create for someone else (sysadmin only)
    // async create(request) {
    //     return postJsonAcceptJson(`${this.url}/account/create`, request, null, { requestHeaders: this.requestHeaders });
    // }

    async get(request) {
        return getJson(`${this.url}/state`, request, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        return postJsonAcceptJson(`${this.url}/edit`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(request) {
        return postJsonAcceptJson(`${this.url}/delete`, request, null, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a resource class to manage authorized client programs
 */
class Client {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/client`, request, null, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        return postJsonAcceptJson(`${this.url}/edit/client`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/client`, null, query, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/client`, query, { requestHeaders: this.requestHeaders });
    }

    /**
     * Search clients assigned to this account
     */
    async search(query) {
        return getJson(`${this.url}/search/client`, query, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a resource class to manage authorization tokens for client software
 * using the server's Client API.
 */
class ClientToken {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/client-token`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/client-token`, null, query, { requestHeaders: this.requestHeaders });
    }

    /**
     * Search available client tokens assigned to this account
     */
    async search(query) {
        return getJson(`${this.url}/search/client-token`, query, { requestHeaders: this.requestHeaders });
    }
}

/*
TODO: where to put invitations? separate class?
    async invite(request) {
        return postJsonAcceptJson(`${this.url}/user/invite`, request, null, { requestHeaders: this.requestHeaders });
    }
*/

// TODO: separate realm-image into RealmImage class; can loginshield info be represented as a realm setting instead of special API?
class Realm {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async get(query) {
        return getJson(`${this.url}/state/realm`, query, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/create/realm`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(request) {
        return postJsonAcceptJson(`${this.url}/delete/realm`, request, null, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/realm`, request, query, { requestHeaders: this.requestHeaders });
    }

    async list(request) {
        return getJson(`${this.url}/search/realm`, request, { requestHeaders: this.requestHeaders });
    }

    async editImage(query, request) {
        return postUploadAcceptJson(`${this.url}/edit/realm-image`, request, query, { requestHeaders: this.requestHeaders });
    }

    async deleteImage(query) {
        return postJsonAcceptJson(`${this.url}/delete/realm-image`, null, query, { requestHeaders: this.requestHeaders });
    }

    async getImage(query) {
        return getJson(`${this.url}/state/realm-image`, query, { requestHeaders: this.requestHeaders });
    }

    async getLoginShieldInfo(query) {
        return getJson(`${this.url}/state/realm-loginshield`, query, { requestHeaders: this.requestHeaders });
    }

    async finishWebauthzRequest(query, request) {
        return postJsonAcceptJson(`${this.url}/grant/webauthz`, request, query, { requestHeaders: this.requestHeaders });
    }

    async updateLoginShieldRealmInfo(query, request) {
        return postJsonAcceptJson(`${this.url}/edit/realm-loginshield`, request, query, { requestHeaders: this.requestHeaders });
    }
}

class BrowserClient {
    constructor(context = {}) {
        this.self = new Account(context);
        // this.client = new Client(context); // see RealmClient in account-realm
        // this.clientToken = new ClientToken(context); // see RealmClientToken in account-realm
        this.realm = new Realm(context);
    }
}

export default BrowserClient;
export {
    Account,
    Client,
    ClientToken,
    Realm,
};
