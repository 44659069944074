import Vue from 'vue';
import MainLayout from '../layouts/MainLayout.vue';
// import DashboardLayout from '../layouts/DashboardLayout.vue';
// import SettingsLayout from '../layouts/SettingsLayout.vue';
// import WorkspaceLayout from '../layouts/WorkspaceLayout.vue';

function setup() {
    Vue.component('main-layout', MainLayout);
    // Vue.component('dashboard-layout', DashboardLayout);
    // Vue.component('settings-layout', SettingsLayout);
    // Vue.component('workspace-layout', WorkspaceLayout);
}

export default { setup };
