/*!
Copyright (C) 2020 Cryptium Corporation. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

/*
async function postUploadAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}
*/

class BrandProfilePartner {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async startConnect(request = {}) {
        return postJsonAcceptJson(`${this.url}/rpc/start-brandprofile-partner-connect`, request, /* query: */ null, { requestHeaders: this.requestHeaders });
    }

    async verifyConnect(token) {
        return postJsonAcceptJson(`${this.url}/rpc/verify-brandprofile-partner-connect`, { token }, /* query: */ null, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a resource class to manage authorized applications to participate in SSO with a realm
 */
class RealmApp {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/realm-app`, request, null, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        return postJsonAcceptJson(`${this.url}/edit/realm-app`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/realm-app`, null, query, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/realm-app`, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/realm-app`, query, { requestHeaders: this.requestHeaders });
    }
}

class RealmAppTag {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/realm-app-tag`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/realm-app-tag`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/realm-app-tag`, query, { requestHeaders: this.requestHeaders });
    }
}

class RealmUser {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/realm-user`, request, null, { requestHeaders: this.requestHeaders });
    }

    async activate(request) {
        return postJsonAcceptJson(`${this.url}/activate/realm-user`, request, null, { requestHeaders: this.requestHeaders });
    }

    async search(request) {
        return getJson(`${this.url}/search/realm-user`, request, { requestHeaders: this.requestHeaders });
    }

    // query should be like { id }
    async get(query) {
        return getJson(`${this.url}/state/realm-user`, query, { requestHeaders: this.requestHeaders });
    }

    // query should be like { id }
    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/realm-user`, request, query, { requestHeaders: this.requestHeaders });
    }

    // query should be like { id }
    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/realm-user`, query, null, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a resource class to manage authorized client programs
 */
class RealmClient {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/realm-client`, request, null, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        return postJsonAcceptJson(`${this.url}/edit/realm-client`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/realm-client`, null, query, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/realm-client`, query, { requestHeaders: this.requestHeaders });
    }

    /**
     * Search clients assigned to this account
     */
    async search(query) {
        return getJson(`${this.url}/search/realm-client`, query, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a resource class to manage authorization tokens for client software
 * using the server's Client API.
 */
class RealmClientToken {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/realm-client-token`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/realm-client-token`, null, query, { requestHeaders: this.requestHeaders });
    }

    /**
     * Search available client tokens
     */
    async search(query) {
        return getJson(`${this.url}/search/realm-client-token`, query, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a resource class to manage authorization tokens for client software
 * using the server's Client API.
 */
class RealmAccessToken {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/realm-access-token`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/realm-access-token`, null, query, { requestHeaders: this.requestHeaders });
    }

    /**
     * Search available access tokens
     */
    async search(query) {
        return getJson(`${this.url}/search/realm-access-token`, query, { requestHeaders: this.requestHeaders });
    }
}

class BrowserClient {
    constructor(context = {}) {
        this.brandProfilePartner = new BrandProfilePartner(context);
        this.realmApp = new RealmApp(context);
        this.realmAppTag = new RealmAppTag(context);
        this.realmUser = new RealmUser(context);
        this.realmClient = new RealmClient(context);
        this.realmClientToken = new RealmClientToken(context);
        this.realmAccessToken = new RealmAccessToken(context);
    }
}

export default BrowserClient;
export {
    BrandProfilePartner,
    RealmApp,
    RealmAppTag,
    RealmUser,
    RealmClient,
    RealmClientToken,
    RealmAccessToken,
};
