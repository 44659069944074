<template>
    <v-avatar :size=size>
        <v-img v-if="useCustomImage" :src="iconData"></v-img>
        <v-gravatar v-if="useGravatar" :email=attr.email :size="size"></v-gravatar>
        <font-awesome-icon v-if="useDefaultIcon" icon="user-circle" fixed-width size="2x"></font-awesome-icon>
    </v-avatar>
</template>

<script>
import gravatar from 'vue-gravatar';

export default {

    props: {
        attr: {
            type: Object,
            default() {
                return {
                    icon: '', // base64 icon data
                    isGravatarEnabled: false, // for gravatar (requires email)
                    email: '', // for gravatar (requires isGravatarEnabled)
                };
            },
        },
        size: {
            type: Number,
            default: 500,
        },
    },

    components: {
        'v-gravatar': gravatar,
    },

    computed: {
        iconData() {
            if (this.attr.icon) {
                return `data:image/png;base64,${this.attr.icon}`;
            }
            return '';
        },
        useCustomImage() {
            // only if a custom icon is available
            if (this.attr.icon) {
                return true;
            }
            return false;
        },
        useGravatar() {
            // not if useCustomImage
            if (this.attr.icon) {
                return false;
            }
            // only if gravatar enabled and there's a profile email to use
            if (this.attr.isGravatarEnabled && this.attr.email) {
                return true;
            }
            return false;
        },
        useDefaultIcon() {
            // not if useCustomImage
            if (this.attr.icon) {
                return false;
            }
            // not if useGravatar
            if (this.attr.isGravatarEnabled && this.attr.email) {
                return false;
            }
            // default
            return true;
        },
    },

};
</script>
