import AccountClient from './client/account';
import AccountRealmClient from './client/account-realm';
import MainClient from './client/main';
import UserClient from './client/user';

/* eslint-disable dot-notation */

const clientMap = {}; // client id -> client instance
const client = {
    account(accountId) {
        const idx = `account:${accountId}`;
        if (!clientMap[idx]) {
            clientMap[idx] = new AccountClient({ endpoint: '/', serviceEndpoint: `/api/browser/account/${accountId}`, accountId });
        }
        return clientMap[idx];
    },
    accountRealm(accountId, realmId) {
        const idx = `accountRealm:${accountId}:${realmId}`;
        if (!clientMap[idx]) {
            clientMap[idx] = new AccountRealmClient({
                endpoint: '/',
                serviceEndpoint: `/api/browser/account/${accountId}/realm/${realmId}`,
                accountId,
                realmId,
            });
        }
        return clientMap[idx];
    },
    main() {
        if (!clientMap['main']) {
            clientMap['main'] = new MainClient({ endpoint: '/', serviceEndpoint: '/api/browser' });
        }
        return clientMap['main'];
    },
    user(userId) {
        const idx = `user:${userId}`;
        if (!clientMap[idx]) {
            clientMap[idx] = new UserClient({ endpoint: '/', serviceEndpoint: `/api/browser/user/${userId}`, userId });
        }
        return clientMap[idx];
    },
};

export default client;
