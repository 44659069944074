/*!
Copyright (C) 2020 Cryptium Corporation. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

class Account {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/system`;
        this.requestHeaders = context.requestHeaders;
    }

    async search(request) {
        return getJson(`${this.url}/search/account`, request, { requestHeaders: this.requestHeaders });
    }

    // async create(request) {
    //     return postJsonAcceptJson(`${this.url}/account/create`, request, null, { requestHeaders: this.requestHeaders });
    // }

    // async delete(request) {
    //     return postJsonAcceptJson(`${this.url}/account/delete`, request, null, { requestHeaders: this.requestHeaders });
    // }

    // async get(request) {
    //     return getJson(`${this.url}/account`, request, { requestHeaders: this.requestHeaders });
    // }
}

class Authn {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async get(request) {
        return getJson(`${this.url}/authn/session`, request, { requestHeaders: this.requestHeaders });
    }

    async signup(request) {
        return postJsonAcceptJson(`${this.url}/authn/signup`, request, null, { requestHeaders: this.requestHeaders });
    }

    async startLogin(request) {
        return postJsonAcceptJson(`${this.url}/authn/login/start`, request, null, { requestHeaders: this.requestHeaders });
    }

    async checkLogin(request) {
        return postJsonAcceptJson(`${this.url}/authn/login/check`, request, null, { requestHeaders: this.requestHeaders });
    }

    async prefsRedirect(request) {
        return postJsonAcceptJson(`${this.url}/authn/prefs-redirect`, request, null, { requestHeaders: this.requestHeaders });
    }

    async logout(request = {}) {
        return postJsonAcceptJson(`${this.url}/authn/logout`, request, null, { requestHeaders: this.requestHeaders });
    }

    async startVerifyEmail(request) {
        return postJsonAcceptJson(`${this.url}/authn/email/start`, request, null, { requestHeaders: this.requestHeaders });
    }

    async checkVerifyEmail(request) {
        return postJsonAcceptJson(`${this.url}/authn/email/token`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class System {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/system`;
        this.requestHeaders = context.requestHeaders;
    }

    // async getInfo() {
    //     return getJson(`${this.url}`);
    // }

    async getVersion() {
        return getJson(`${this.url}/state/version`, null, { requestHeaders: this.requestHeaders });
    }
}

class Setting {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/system`;
        this.requestHeaders = context.requestHeaders;
    }

    async search() {
        return getJson(`${this.url}/search/setting`, null, { requestHeaders: this.requestHeaders });
    }

    async edit(request) {
        return postJsonAcceptJson(`${this.url}/edit/setting`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class Interaction {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/interaction/create`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/interaction`, { id }, { requestHeaders: this.requestHeaders });
    }

    async resume(token) {
        return postJsonAcceptJson(`${this.url}/interaction/resume`, { token }, null, { requestHeaders: this.requestHeaders });
    }

    async edit(id, message) {
        return postJsonAcceptJson(`${this.url}/interaction/edit`, message, { id }, { requestHeaders: this.requestHeaders });
    }

    async getTokenStatus(tokenId) {
        console.log('getTokenStatus');
        return getJson(`${this.url}/interaction/token/status`, { tokenId }, { requestHeaders: this.requestHeaders });
    }
}

class Realm {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/system`;
        this.requestHeaders = context.requestHeaders;
    }

    async search(request) {
        return getJson(`${this.url}/search/realm`, request, { requestHeaders: this.requestHeaders });
    }

    // async create(request) {
    //     return postJsonAcceptJson(`${this.url}/account/create`, request, null, { requestHeaders: this.requestHeaders });
    // }

    // async delete(request) {
    //     return postJsonAcceptJson(`${this.url}/account/delete`, request, null, { requestHeaders: this.requestHeaders });
    // }

    // async get(request) {
    //     return getJson(`${this.url}/account`, request, { requestHeaders: this.requestHeaders });
    // }
}

class BrowserClient {
    constructor(context = {}) {
        this.account = new Account(context); // TODO: move to staff web app so it's not confusing; this is syasdmin access to account list
        this.authn = new Authn(context);
        this.interaction = new Interaction(context);
        this.realm = new Realm(context);
        this.system = new System(context);
        this.setting = new Setting(context);
    }
}

export default BrowserClient;
export {
    Account,
    Authn,
    Interaction,
    Realm,
    System,
    Setting,
};
