<template>
    <nav class="no-print">
        <v-app-bar fixed fade-on-scroll elevation="1" dense color="white" app>
            <v-toolbar-title style="font-size: 24px!important;">
                <router-link :to="{ name: 'front' }" style="text-decoration: none;" v-if="!isEnterpriseBranding">
                    <span class="text-overline indigo--text">LoginFront Admin</span>
                    <!-- <v-img alt="Unicorn Springs" src="@/assets/logo/unicornsprings/UnicornSprings_light_264x48.png" class="mr-1 float-left" width="196" height="36" fit="inside"></v-img> -->
                </router-link>
                <router-link :to="{ name: 'account-dashboard', params: { accountId: account.id } }" style="text-decoration: none;" v-if="isEnterpriseBranding">
                    <span class="indigo--text">{{ account.name }}</span>
                    <!-- TODO: for enterprise branding, show the enteprise logo here; add @ LoginFront ? or maybe a small LoginFront logomark? or else the only hint is the tab title -->
                </router-link>
            </v-toolbar-title>
            <v-progress-linear
                :active="isLoading"
                :indeterminate="isLoading"
                absolute
                bottom
                color="indigo"
            ></v-progress-linear>
            <v-spacer></v-spacer>
            <template v-if="isAuthenticated && account">
                <v-menu offset-y right open-on-click open-on-hover close-delay="100">
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" :style="primaryIconButtonStyle">
                            <font-awesome-icon :icon="['fas', 'folder-open']" fixed-width></font-awesome-icon>
                        </v-btn>
                    </template>
                    <v-list class="ma-0 pa-0">
                        <v-list-item :to="{ name: 'account-search-realm', params: { accountId: this.$route.params.accountId }, query: { t: Date.now() } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'globe']" :color="primaryColor" fixed-width></font-awesome-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Realms
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- <v-list-item :to="{ name: 'account-search-user', params: { accountId: this.$route.params.accountId }, query: { t: Date.now() } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'user-friends']" :color="primaryColor" fixed-width></font-awesome-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Users
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item> -->
                    </v-list>
                </v-menu>
                <TextButton class="text-headline" style="font-size: 1.1em; line-height: 1.1em;" @click="navigateToAccountDashboard">
                    <span v-html="accountNameNbsp"></span>
                </TextButton>
                <v-menu offset-y right open-on-click open-on-hover close-delay="100">
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" :style="primaryIconButtonStyle">
                            <font-awesome-icon :icon="['fas', 'plus']" fixed-width></font-awesome-icon>
                        </v-btn>
                    </template>
                    <v-list class="ma-0 pa-0">
                        <v-list-item :to="{ name: 'account-create-realm', params: { accountId: $route.params.accountId } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'globe']" :color="primaryColor" fixed-width></font-awesome-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Create realm
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
            <!-- TODO: restore the "+" menu here for new realm, new user in realm, etc. with available entries depending on context ; for example new user in realm only available when URL has realm id, etc.
            <v-menu offset-y left open-on-click open-on-hover close-delay="100" v-if="isAuthenticated && $route.params.organizationId">
                <template #activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="indigo--text text--darken-2">
                        <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
                    </v-btn>
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item :to="{ name: 'organization-create-product', params: { organizationId: $route.params.organizationId } }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'box']" class="indigo--text text--darken-2" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                New Product
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    < ! - - <v-list-item :to="{ name: 'organization-create-customer', params: { organizationId: $route.params.organizationId } }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'folder']" class="indigo--text text--darken-2" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                New Customer
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item> - - >
                    < ! - - TODO: new customer, new order, new invoice, ... - - >
                </v-list>
            </v-menu>
            -->
            <!-- TODO: add "search" feature when we have an API to search all realms, users, etc. available to signed in admin user -->
            <!-- <v-btn icon color="indigo" @click="search">
                <font-awesome-icon :icon="['fas', 'search']" fixed-width/>
            </v-btn> -->
            <v-menu v-model="helpMenu" offset-y open-on-click open-on-hover close-delay="100">
                <template #activator="{ on, attrs }">
                    <v-btn icon color="indigo" v-bind="attrs" v-on="on">
                        <!-- TODO: when we upgrade to fontawesome 6.x this should be changed to 'message-question', or at least check how it looks that way -->
                        <font-awesome-icon :icon="['fas','question']" fixed-width/>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-text>
                        Questions or comments?
                        <a :href="contactURL" target="_blank">Contact us!</a>
                    </v-card-text>
                </v-card>
            </v-menu>
            <v-btn outlined color="indigo" v-if="!isAuthenticated" @click="login">
                <!-- TODO: when we upgrade to fontawesome 6.x this should be changed to 'message-question', or at least check how it looks that way -->
                <!-- <font-awesome-icon :icon="['fas','question']" fixed-width/> -->
                Sign in
            </v-btn>
            <v-menu offset-y left open-on-click open-on-hover close-delay="100" v-if="isAuthenticated">
                <template #activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="indigo--text">
                        <Avatar :attr="mainProfile" :size="28"/>
                    </v-btn>
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item v-if="user">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ user.display_name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ user.email }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'user-dashboard' }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'chart-line']" class="indigo-text" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Dashboard
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'profile' }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'user']" class="indigo-text" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Profile
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'user-account-list' }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'folder']" class="indigo-text" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Accounts
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <template v-if="isPermitSystemAdmin">
                    <v-divider></v-divider>
                    <v-list-item :to="{ name: 'service-admin' }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'id-badge']" class="indigo-text" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Service Admin
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </template>
                    <v-divider></v-divider>
                    <v-list-item @click="onLogout">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'shield-alt']" class="indigo-text" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Logout
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <!-- <div>
                        <v-tabs vertical slider-color="green darken-2" class="menutabs">
                            <v-tab :to="{ name: 'user-dashboard' }" v-show="false"></v-tab>
                            <v-tab :to="{ name: 'profile' }">Profile</v-tab>
                            <v-tab :to="{ name: 'user-organization-list' }">Organizations</v-tab>
                            <v-tab :to="{ name: 'user-dashboard' }">Dashboard</v-tab>
                            <v-tab :to="{ name: 'service-admin' }" v-if="isPermitSystemAdmin">Service admin</v-tab>
                            <v-divider></v-divider>
                            <v-tab @click="onLogout">Logout</v-tab>
                        </v-tabs>
                    </div> -->
                </v-list>
            </v-menu>
            <!-- TODO: REMOVE THE FOLLOWING -->
            <!-- <div v-if="!isAuthenticated">
                <div v-show="$vuetify.breakpoint.smAndUp">
                    <v-tabs slider-color="indigo" right>
                        <v-tab to="/" v-show="false"></v-tab>
                    </v-tabs>
                </div>
                <div v-show="$vuetify.breakpoint.xsOnly">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn text v-on="on">
                                <font-awesome-icon right class="indigo--text" icon="bars" fixed-width/>
                            </v-btn>
                        </template>
                        <v-tabs vertical slider-color="indigo">
                            <v-tab to="/" v-show="false"></v-tab>
                        </v-tabs >
                    </v-menu>
                </div>
            </div> -->
            <!-- <div v-if="isAuthenticated">
                <v-tabs slider-color="indigo">
                    <v-tab to="/dashboard" v-show="false"></v-tab>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-tab v-on="on" class="indigo--text">
                                <Avatar :attr="mainProfile" :size="36"/>
                                <font-awesome-icon icon="caret-down" class="pl-2 indigo--text" fixed-width size="1x"/>
                            </v-tab>
                        </template>
                        <v-list class="ma-0 pa-0">
                            <div>
                                <v-tabs vertical slider-color="indigo" class="menutabs">
                                    <v-tab to="/dashboard" v-show="false"></v-tab>
                                    <v-tab to='/user' v-show="$vuetify.breakpoint.xsOnly">Users</v-tab>
                                    <v-tab to='/group' v-show="$vuetify.breakpoint.xsOnly">Groups</v-tab>
                                    <v-tab to='/access-policy' v-show="$vuetify.breakpoint.xsOnly">Access</v-tab>
                                    <v-tab to='/profile'>Profile</v-tab>
                                    <v-tab to='/account'>Accounts</v-tab>
                                    <v-tab :to="{ name: 'service-admin' }" v-if="isPermitSystemAdmin">Service admin</v-tab>
                                    <v-divider></v-divider>
                                    <v-tab @click="onLogout">Logout</v-tab>
                                </v-tabs>
                            </div>
                        </v-list>
                    </v-menu>
                </v-tabs>
            </div> -->
        </v-app-bar>
    </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Avatar from '@/components/Avatar.vue';
import TextButton from '@/components/TextButton.vue';

export default {
    components: {
        Avatar,
        TextButton,
    },

    computed: {
        ...mapState({
            isAuthenticated: (state) => state.session.isAuthenticated,
            user: (state) => state.user,
            account: (state) => state.account,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            primaryColor: 'primaryColor',
            primaryTextStyle: 'primaryTextStyle',
            primaryIconButtonStyle: 'primaryIconButtonStyle',
            isPermitSystemAdmin: 'isPermitSystemAdmin',
        }),
        mainProfile() {
            return { name: this.user.name, email: this.user.email };
        },
        contactURL() {
            const websiteURL = new URL(process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://loginfront.com');
            websiteURL.pathname = '/contact/';
            return websiteURL.toString();
        },
        isEnterpriseBranding() {
            return false; // TODO: when the selected account has this feature enabled, then we should detect and return true here, so we'll show their logo on the left side of the navbar instead of the LoginFront logo
        },
        accountNameNbsp() {
            // the intent is to replace spaces with &nbsp; but because we have to use v-html to render this,
            // we ALSO need to escape angle brackets and quotes to prevent XSS attacks with the account name
            // itself (because user can edit the account name, and then admins can look at it, so the attack
            // would be an authorized user trying to take over an admin's session)
            return this.account?.name
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/'/, '&apos;')
                .replace(/ /g, '&nbsp;');
        },
    },

    methods: {
        login() {
            const { fullPath } = this.$router.currentRoute;
            this.$router.push({ name: 'login', query: { next: fullPath } });
        },
        async onLogout() {
            console.log('onLogout: redirecting to front page');
            await this.$store.dispatch('logout');
            if (this.$route.name !== 'front') {
                this.$router.push({ name: 'front' });
            }
        },
        search() {
            if (this.$route.name === 'search') {
                // reset the search input to empty string
                this.$bus.$emit('search', '');
            } else {
                this.$router.push({ name: 'search' /* , query: { q: this.searchQuery } */ });
            }
        },
        navigateToAccountDashboard() {
            this.$router.push({ name: 'account-dashboard', params: { accountId: this.account.id }, query: { t: Date.now() } });
        },
    },

    mounted() {
        console.log(`navbar route params ${JSON.stringify(this.$route.params)}`);
        if (this.$route.params.realmId) {
            console.log(`navabar realm ${this.$route.params.realmId}`);
        } else {
            console.log('navbar no realm');
        }
    },
};
</script>

<style>
    @media print {
        .no-print {
            display: none !important;
        }
    }
    @media screen {
        div.menutabs div[role="tablist"] { width: 100%; }
        div.menutabs [role="tab"] { justify-content: flex-start; padding-left: 48px;}
    }
</style>
