/*!
Copyright (C) 2020 Cryptium Corporation. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

/*
async function postUploadAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}
*/

class Account {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async search(request) {
        return getJson(`${this.url}/search/account`, request, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/account`, request, null, { requestHeaders: this.requestHeaders });
    }

    // to delete an account, use this.$client.account(accountId).self.delete();
    // async delete(request) {
    //     return postJsonAcceptJson(`${this.url}/delete/account`, request, null, { requestHeaders: this.requestHeaders });
    // }

    // to get account info, use this.$client.account(accountId).self.get();
    // async get(request) {
    //     return getJson(`${this.url}/state/account`, request, { requestHeaders: this.requestHeaders });
    // }
}

class User {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    // TODO: is this really an invitation? because a user cannot create their own user... and they can't create others... have to invite them.
    // async create(request) {
    //     return postJsonAcceptJson(`${this.url}/create/user`, request, null, { requestHeaders: this.requestHeaders });
    // }

    // TODO: maybe invitations go in a separate class? because it's an invitation to join a specific account... but the invitee might or might not already be a user...
    // async invite(request) {
    //     return postJsonAcceptJson(`${this.url}/user/invite`, request, null, { requestHeaders: this.requestHeaders });
    // }

    // TODO: move to unicorn springs for user registration
    // request like { email (string), agreeToTerms (boolean) }
    async activate(request) {
        return postJsonAcceptJson(`${this.url}/activate`, request, null, { requestHeaders: this.requestHeaders });
    }

    // TODO: where does this go?  should it be under account ?? to serach ACCOUNT USERS ? so really link-account-user ?  or is it AccountUser under account.js, since account id is specified there...  and UserAccount here... since user id is specified here... but both go to link-account-user API ? or we have separate api endpoints... user/userid/user-account and account/accountid/account-user both going to api/link-account-user.js ?? or just do it from both perspectives separately ??
    // async list(request) {
    //     return getJson(`${this.url}/search/user`, request, { requestHeaders: this.requestHeaders });
    // }

    async get() {
        return getJson(`${this.url}/state`, null, { requestHeaders: this.requestHeaders });
    }

    async edit(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete() {
        return postJsonAcceptJson(`${this.url}/delete`, null, null, { requestHeaders: this.requestHeaders });
    }
}

class UserAuthzToken {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/user-authz-token`, request, null, { requestHeaders: this.requestHeaders });
    }

    async list() {
        return getJson(`${this.url}/search/user-authz-token`, null, { requestHeaders: this.requestHeaders });
    }

    async delete({ id }) {
        return postJsonAcceptJson(`${this.url}/delete/user-authz-token`, null, { id }, { requestHeaders: this.requestHeaders });
    }
}

class UserAuthzCode {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/user-authz-code`, request, null, { requestHeaders: this.requestHeaders });
    }

    async list() {
        return getJson(`${this.url}/search/user-authz-code`, null, { requestHeaders: this.requestHeaders });
    }

    async delete({ id }) {
        return postJsonAcceptJson(`${this.url}/delete/user-authz-code`, null, { id }, { requestHeaders: this.requestHeaders });
    }
}

class Webauthz {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    // for authorization server
    async getAccessPrompt(id) {
        return getJson(`${this.url}/webauthz/prompt`, { id });
    }

    // TODO: check the other api grant/webauthz ... was that supposed tob e the same as this??
    // for authorization server
    async grantAccess(id, permit = {}) {
        return postJsonAcceptJson(`${this.url}/webauthz/prompt`, { id, submit: 'grant', permit });
    }

    // for authorization server
    async denyAccess(id) {
        return postJsonAcceptJson(`${this.url}/webauthz/prompt`, { id, submit: 'deny' });
    }
}

class BrowserClient {
    constructor(context = {}) {
        this.account = new Account(context);
        this.self = new User(context);
        this.userAuthzToken = new UserAuthzToken(context);
        this.userAuthzCode = new UserAuthzCode(context);
        this.webauthz = new Webauthz(context);
    }
}

export default BrowserClient;
export {
    Account,
    User,
    UserAuthzToken,
    UserAuthzCode,
    Webauthz,
};
