<template>
    <component :is="layout">
        <Snackbar :attr="snackbarAttr"/>
        <template v-if="isPublic || (isAuthenticatedReady && isAuthenticated)">
            <router-view></router-view>
        </template>
        <template v-if="!isPublic && !isAuthenticatedReady">
            <v-row style="height: 100%" align="center" justify="center">
                <div class="app-splash-loader"></div>
            </v-row>
        </template>
        <template v-if="!isPublic && isAuthenticatedReady && !isAuthenticated">
            <v-row style="height: 100%" align="center" justify="center">
                <v-col cols="10" sm="8" md="6" lg="4">
                    <LoginCard :nextRoute="this.$router.currentRoute.fullPath"/>
                </v-col>
            </v-row>
        </template>
    </component>
</template>

<style lang="css">
html {
  overflow-y: auto; /* reference: https://vuetifyjs.com/en/getting-started/frequently-asked-questions/ "The scrollbar is showing even though my content is not overflowing vertically" */
}

.app-splash-loader {
    border: 4px solid #9FA8DA; /* indigo lighten-3 */
    border-top: 4px solid #3F51B5; /* indigo */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1.0s linear infinite;
    margin: auto;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

  /* * {
    font-family: 'NunitoSans', sans-serif
  } */
@media print {
  /* removes padding corresponding to navbar and footer when printing document */
  .print {
    padding: 0 !important;
  }
}
</style>

<script>
import { mapState } from 'vuex';
// import '@/assets/font/NunitoSans/NunitoSans.css'
import LoginCard from '@/components/LoginCard.vue';
import Snackbar from '@/components/Snackbar.vue';

export default {
    name: 'App',

    components: {
        LoginCard,
        Snackbar,
    },

    data: () => ({
        snackbarAttr: {},
    }),

    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        isPublic() {
            return this.$route.meta.isPublic ?? false;
        },
        layout() {
            return this.$route.meta.layout ?? 'main-layout';
        },
    },

    watch: {
        $route(newValue, oldValue) {
            this.switchAccount(newValue.params.accountId, oldValue.params.accountId);
        },
    },

    methods: {
        async switchAccount(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                console.log(`App.vue detected change in accountId from ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
                await this.$store.dispatch('loadAccount', { accountId: newValue });
            }
            if (!newValue && oldValue) {
                console.log(`App.vue detected cleared accountId from ${JSON.stringify(oldValue)}`);
                this.$store.commit('setAccount', null);
            }
        },
    },

    created() {
        console.log('app.vue: created, initializing');
        this.$store.dispatch('init');

        // call when tab is selected
        window.onfocus = () => {
            this.$store.dispatch('refresh');
            this.$store.commit('focus', Date.now());
        };

        // listen for snackbar events coming from any component
        this.$bus.$on('snackbar', (attr) => { this.snackbarAttr = attr; });
    },

    mounted() {
        this.switchAccount(this.$route.params.accountId, null);
    },
};
</script>
